//Required imports
import { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { API } from '../../firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCharges } from '../../redux/charges/chargesSlice';
import { addSenderData, addCurrency } from '../../redux/sender/senderSlice';
import { message } from 'antd';

//Styling
import 'antd/dist/antd.css';
import './Amount.css';
import { clearBankError, loadBankWallet } from '../../redux/bank/bankSlice';

//Error component
export const Error = ({ err, style }) => {
  return (
    <div className={err ? 'error' : ''} style={style}>
      <BsExclamationCircle />
      {err}
    </div>
  );
};

export const isNumber = (str) => {
  if (str.trim() === '') {
    return false;
  }

  return !isNaN(str);
};

//Amount component
const { Option } = Select;

export const Amount = () => {
  //Redux state
  const recipient = useSelector((state) => state.recipient);
  const charge = useSelector((state) => state.charges);
  const bank = useSelector((state) => state.bank);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //useState
  const [senderInfo, setSenderInfo] = useState({
    firstName: '',
    lastName: '',
    amount: '',
    note: '',
  });

  //Set default currency
  const [currency, setCurrency] = useState();

  useEffect(() => {
    const setDefaultCurrency = () => {
      if (recipient.recipientDetails) {
        const mainwallet = recipient.recipientDetails.wallets.filter((wallet) => wallet.isMainWallet);
        if (mainwallet.length) {
          return mainwallet[0].currency;
        } else {
          return recipient.recipientDetails.wallets[0].currency;
        }
      }
    };

    setCurrency(setDefaultCurrency());
  }, [recipient.recipientDetails]);

  //Currency change
  const handleCurrencyChange = (value) => {
    setCurrency(value);
  };

  //Handle sender info change
  const handleSenderInfo = (e) => {
    let value = e.target.value;
    setSenderInfo({
      ...senderInfo,
      [e.target.name]: value,
    });
  };

  const username = recipient.recipientDetails ? recipient.recipientDetails.email : '';

  const paymentMethodIdMomo = recipient.recipientDetails
    ? recipient.recipientDetails.momoWallets.includes(currency)
      ? API.momoId
      : API.bankId
    : '';

  const chargesInput = {
    username,
    paymentMethodId: paymentMethodIdMomo,
    currency,
    amount: 42,
  };

  //Fetch max and minimum
  useEffect(() => {
    dispatch(fetchCharges(chargesInput));
  }, [currency]);

  //Bank
  const bankInput = {
    username: recipient.recipientDetails ? recipient.recipientDetails.email : '',
    paymentMethodId: API.bankId,
    currency: currency,
    amount: parseFloat(senderInfo.amount),
  };

  //Handle button click

  const handleBtnClick = async () => {
    if (
      senderInfo.amount &&
      senderInfo.firstName &&
      senderInfo.lastName &&
      senderInfo.note &&
      recipient.recipientDetails.canWithdrawCash &&
      recipient.recipientDetails.momoWallets.includes(currency) &&
      !minError &&
      !maxError
    ) {
      dispatch(addSenderData(senderInfo));
      dispatch(addCurrency(currency));
      navigate(`/${recipient.recipientDetails.tag}/pay/phone`);
    }

    if (
      senderInfo.amount &&
      senderInfo.firstName &&
      senderInfo.lastName &&
      recipient.recipientDetails.canWithdrawCash &&
      recipient.recipientDetails.bankWallets.includes(currency) &&
      !minError &&
      !maxError
    ) {
      dispatch(addSenderData(senderInfo));
      dispatch(addCurrency(currency));
      dispatch(loadBankWallet(bankInput));
    }

    if (!recipient.recipientDetails.canWithdrawCash) {
      navigate(`/${recipient.recipientDetails.tag}/pa/unsupported`);
    }
  };

  //Navigate to bank
  useEffect(() => {
    if (bank?.bankLoadDetails) {
      navigate(`/${recipient.recipientDetails.tag}/pay/bank`);
    }
  }, [bank.bankLoadDetails]);

  useEffect(() => {
    if (bank.error) {
      message.error('Could not get bank details at this moment.');
      dispatch(clearBankError());
    }
  }, [bank.error]);

  //Set min and max errors
  let minError;
  let maxError;

  if (
    charge.chargesDetails &&
    currency !== '' &&
    senderInfo.amount !== '' &&
    senderInfo.amount < charge.chargesDetails.minLoadAmount
  ) {
    minError = `The minimum amount is ${currency} ${charge.chargesDetails.minLoadAmount.toLocaleString()}`;
  } else if (
    charge.chargesDetails &&
    currency !== '' &&
    senderInfo.amount !== '' &&
    senderInfo.amount > charge.chargesDetails.maxLoadAmount
  ) {
    maxError = `The maximum amount is ${currency} ${charge.chargesDetails.maxLoadAmount.toLocaleString()}`;
  }

  return (
    <div className='amount'>
      <Form onFinish={handleBtnClick}>
        <label>Enter amount to send</label>
        <Form.Item>
          <Input
            addonBefore={
              <Select
                placeholder='UGX'
                className='select-before'
                style={{ width: '105px' }}
                name='currency'
                value={currency}
                onChange={handleCurrencyChange}
              >
                {recipient.recipientDetails &&
                  recipient.recipientDetails.wallets.map((wallet) => (
                    <Option key={wallet.currency} value={wallet.currency}>
                      <img src={wallet.flag} alt='' className='amount-flag' />
                      {wallet.currency}
                    </Option>
                  ))}
              </Select>
            }
            placeholder='0000'
            name='amount'
            value={senderInfo.amount}
            onChange={handleSenderInfo}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            inputMode='numeric'
            autoFocus
            required
          />
        </Form.Item>

        {minError ? <Error err={minError} /> : maxError ? <Error err={maxError} /> : ''}

        <label className='enter-name'>Enter your name</label>

        <Form.Item>
          <Input.Group compact>
            <Input
              style={{
                width: '50%',
              }}
              placeholder='First name'
              name='firstName'
              value={senderInfo.firstName}
              onChange={handleSenderInfo}
              required
            />
            <Input
              style={{
                width: '50%',
              }}
              placeholder='Second name'
              name='lastName'
              value={senderInfo.lastName}
              onChange={handleSenderInfo}
              required
            />
          </Input.Group>
        </Form.Item>

        <label className='enter-name'>Add Note</label>
        <Form.Item>
          <Input placeholder='Add Note' name='note' value={senderInfo.note} onChange={handleSenderInfo} required />
        </Form.Item>

        <Form.Item>
          <Button
            loading={bank.loading}
            style={{
              backgroundColor:
                senderInfo.amount &&
                senderInfo.firstName &&
                senderInfo.lastName &&
                senderInfo.note &&
                !minError &&
                !maxError
                  ? '#5a50fe'
                  : '#e2e0ff',
            }}
            htmlType='submit'
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
